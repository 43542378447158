:root {
    --pie-slice-1-color: #2d3e50;
    --pie-slice-2-color: #395167;
    --pie-slice-3-color: #466380;
    --pie-slice-4-color: #547698;
    --pie-slice-5-color: #6185A8;
    --pie-slice-6-color: #6E8FAF;
}
#mychart {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 1em;
    position: relative;
    border: .15em solid #1a1d25;
    box-shadow: 0 .075em .2em .05em rgba(0,0,0,.25);
    background-origin: border-box; 
    background-image: conic-gradient(
        var(--pie-slice-1-color) calc(3.6deg * var(--slice1, 100)),
        var(--pie-slice-2-color) 0 calc(3.6deg * var(--slice2, 100)),
        var(--pie-slice-3-color) 0 calc(3.6deg * var(--slice3, 100)),
        var(--pie-slice-4-color) 0 calc(3.6deg * var(--slice4, 100)),
        var(--pie-slice-5-color) 0 calc(3.6deg * var(--slice5, 100)),
        var(--pie-slice-6-color) 0 calc(3.6deg * var(--slice6, 100))
    );

    .chart-legend {
        float: right;
        padding: 0;
        margin: 0 -30px;
        @include breakpoint($medium) {
            margin: 0 -130px;
        }
        .slice {
            position:relative;
            text-align:left;
            padding-right: 4px;
            span{
                display: inline-block;
                margin-right: 4px;
                font-size: 0.75em;
                margin-left: 4px;
                padding: 1px;
                width: 40px;
                text-align: center;
                box-shadow: 1px 1px 1px black;
            }

            &.slice1 span {
                background-color: var(--pie-slice-1-color);
            }   

            &.slice2 span {
                background-color: var(--pie-slice-2-color);
            }   

            &.slice3 span {
                background-color: var(--pie-slice-3-color);
            }  

            &.slice4 span {
                background-color: var(--pie-slice-4-color);
            }   

            &.slice5 span {
                background-color: var(--pie-slice-5-color);
            }   

            &.slice6 span {
                background-color: var(--pie-slice-6-color);
            }
        }
    }
    .slice {
        position: absolute;
        display: block;
        font-family: sans-serif;
        font-size: 14px;
        text-align: center;

        span{
            display:block;
        }

        &.personal {
            top: 25%;
            left: 10%;
        }

        &.work {
            top: 25%;
            right: 20%;
        }

        &.commute {
            bottom:20%;
            right:10%;
        }

        &.sleep {
            bottom:15%;
            left:30%;
        }
   

    }
}


.bchart {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(100, 1fr);
    grid-column-gap: 5px;
    height: 300px;
    width: 100%;
    padding: 5px 10px;

    @include breakpoint($large) {
        height: 400px;
    }

    [class*="bar"] {
      border-radius: 5px 5px 0 0;
      transition: all .6s ease;
      background-color: #395167;
      grid-row-start: 1;
      grid-row-end: 111;
      display:flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        font-family: sans-serif;
        font-size: 12px;
        padding: 2px 8px;    
        margin: 0 auto;

        &.label {
            margin-bottom: 4px;
            margin-top: 10px;
        }
      }
    }

    $totalRows: 111;

    @mixin chartValue($data) {
      $result: $totalRows - $data;
      grid-row-start: $result;
    }

    @for $i from 1 through $totalRows {
      .bar-#{$i} {
        @include chartValue($i);
      }
    }   
}
