/* ==========================================================================
   Site-wide
   ========================================================================== */

.skip-links {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

#menu-toggle {
  display: block;
  margin: 0;
  padding: 0.25em 0.75em 0.5em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  @include breakpoint($medium) {
    display: none;
  }
}

.navigation-wrapper {
  width: 330px;
  padding: 4px;
  box-shadow: 1px 1px 10px black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;  
  background-color: $nav-background-color;

  @include breakpoint ($large){
    width:500px;
    padding:0;
    background:none;
    box-shadow:none;
    border:0;
    background-color: transparent;
  }

  @include breakpoint ($medium){
    width: 500px;
  }
}
.site-nav {
  display: none;
  margin-bottom: 0.1em;
  text-align: center;

  @include breakpoint($medium){
    margin-bottom: 1em;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0.25em 0.75em;
    list-style: none;
    border-radius: 0 0 (2 * $border-radius) (2 * $border-radius);

    @include breakpoint($medium) {
      display: inline-block;
    }
  }

  li {
    display: inline-block;
    font-size: 0.75em;
  }

  a {
    display: block;
    padding: 0.5em 0.75em;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid;
    border-radius: $border-radius;
  }

  @include breakpoint($medium) {
    display: block;
  }

  &.js-menu-is-open {
    display: block;
    -webkit-animation-name: initial;
    animation-name: initial;
  }
}

.masthead {
  @include clearfix;
  margin-bottom: 3em;
  text-align: center;
}

.site-title {
  margin: 0.5em 0 0;
  padding: 0;
  @include fluid-type($min-vw, $max-vw, $title-min, $title-max);
  text-shadow: 4px 4px 8px #000;
  font-family: $site-title-font-family;
  font-weight: 700;
  display: inline-block;
  a {
    text-decoration: none;
  }
}

.site-description {
  margin-bottom: 1em;
  font-family: $description-font-family;
  @include fluid-type($min-vw, $max-vw, $h5-min, $h5-max);
  font-weight: 400;
  font-style: italic;
}

.site-logo {
  display: inline-block;
  position: relative;
  z-index:-1;
  padding:0 20px 0 0;
  top:-10px
}

.site-logo-img {
  width: $site-logo-width;
  height: $site-logo-height;
  border: 5px solid;
  border-radius: $site-logo-width;

  @include breakpoint($medium) {
    width: 1.5 * $site-logo-width;
    height: 1.5 * $site-logo-height;
    border-radius: 1.5 * $site-logo-width;
  }

  @include breakpoint($large) {
    width: 2 * $site-logo-width;
    height: 2 * $site-logo-height;
    border-radius: 2 * $site-logo-width;
  }
}

.site-footer {
  @include clearfix();
  margin: 2em 0;
  padding: 2em 0;
  text-align: center;
  border-top: 1px solid;

  .social-icons {
    margin-bottom: 0.5em;
  }

  .social-icon {
    display: inline-block;
    padding: 0 0.25em;
  }
}

.copyright {
  font-size: 0.75em;

  p {
    margin: 0;
    padding: 0;
  }
}

.footnotes {
  margin: 2rem 0;
  padding-top: 1rem;
  border-top: 1px solid;
  font-size: 80%;
}

.more-link {
  font-weight: bold;
  text-decoration: none;
}

.back-to-top {
  display: block;
  font-size: 0.75em;
  text-transform: uppercase;
  text-align: right;
  text-decoration: none;
}

.taxonomy-section {
  margin-bottom: 2em;
  padding-bottom: 1em;

  &:not(:last-child) {
    border-bottom: solid 1px;
  }

  + .taxonomy-section {
    margin-top: 2em;
  }
}

.taxonomy-title {
  @include fluid-type($min-vw, $max-vw, 28px, 40px);
  margin:0 2rem;
  font-family: $title-font-family;
}

.taxonomy-index {
  display: grid;
  grid-column-gap: 2em;
  grid-template-columns: repeat(1, 1fr);
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  font-size: 0.9em;
  list-style: none;

  @include breakpoint($large) {
    display: inline-block;
    text-align: center;
  }

  li {
    border-bottom: 1px solid #aaa;
    padding: 0px 0 1px 1px; 
    text-align:left;
    display: inline-block;
    margin: 0.5em 0.8em;
    border-bottom: 1px solid #aaa;
    border-bottom-left-radius:12px;
    padding: 0px 0 2px 2px; 

    @include breakpoint($large) {
    
    }

  }

  a {
    background: none;

    span {
      display: inline-block;
      background: linear-gradient(to bottom, transparent, black);
      border-radius:1em;
      padding:8px 6px;
      line-height: 0.5em;
      font-size: 0.75em;
      margin: 0;
      margin-left: 4px;
      margin-right:4px;
      color: #aaa;
    }
  }

  + .taxonomy-section {
    margin-top: 3em;
  }
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-column: 1 / -1;
  margin: 2em 0;
  width: 100%;

  ul {
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: block;
    float: left;
    margin-left: -1px;

    a {
      display: block;
      margin-bottom: 0.25em;
      padding: 0.5em 1em;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      border: 1px solid;
      border-radius: 0;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    &:first-child {
      margin-left: 0;

      a {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &:last-child {
      a {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

.search-input {
  margin-bottom: 2em;
  padding: 0.5em;
  width: 100%;
  font-size: 0.9em;
  border: 2px solid;
  border-radius: $border-radius;
}

.toc-wrapper {
  display: inline-block;
  margin: 0.5em 0 1em 0;
  border: 0;
  width: 285px;

  @include breakpoint($xlarge) {
    display: none;
    position: fixed;
    left: 0.5em;
    bottom: 0;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }

  h3 {
    letter-spacing: normal;
    display:inline-block;
    margin: 0;
    padding: 0.75rem 1rem;
    font-family: $base-font-family;
    font-size:1em;

    .fa-elementor{
       margin-right:8px;
    }

    .toc-toggle-icon {
      margin-left: 1rem;
      -webkit-transition: -webkit-transform 350ms ease;
      transition: -webkit-transform 350ms ease;
      transition: transform 350ms ease;
      transition: transform 350ms ease, -webkit-transform 350ms ease;
    }

    &.js-toc-is-open {
      .toc-toggle-icon {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  #markdown-toc {
    display: block;
    height: auto;
    margin-bottom: 0;
    padding-left: 2rem;
    overflow: hidden;
    font-size: 0.9rem;
    margin:0;
    list-style:none;
    padding:0;

    @include breakpoint($xlarge){
      -webkit-transition: height 350ms ease;
      transition: height 350ms ease;
    }

    li, a {
      @include breakpoint($xlarge){
        font-size:1em;
        overflow-wrap:break-word;
        color:#aaa;
      }
    }

    li {
      padding: 0.3em 0;
      font-family: $base-font-family;
      list-style-position: inside;
      text-indent: -1.2rem;
      padding-left: 2rem;
      list-style: none;
      
      @include breakpoint($xlarge){
        text-indent: -1rem;
        padding-left: 2.5rem;
      }

      a {
        display: inline-block;
        color: inherit;
        font-size: 1em;
        border:0;

        &:before {
          content: "\025B9";
          color:red;
          padding-right: 10px;
          opacity: 0;
        }

        &.current {
          color: #fff;

          &:before {
            opacity: 1;
            transition: opacity 1s ease-in;
            -moz-transition: opacity 1s ease-in;
            -webkit-transition: opacity 1s ease-in;
          }
        }

        &:hover {
          color: #fff;
        }
      }

      ol {
        padding-left: 0.5rem;
        list-style-type: none;
      }
    }

  }
}
