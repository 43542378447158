/* 
======================================================================
 Page
======================================================================
*/

.page-wrapper {
  margin: 0 auto;
  max-width: $main-max-width;

  .page-title {
    @include fluid-type($min-vw, $max-vw, 38px, 60px);
    margin-bottom: 0.5em;
    font-family: $title-font-family;
    font-weight: $page-title-weight;
    font-style: italic;
  }

  .about.post-stats {
    display:block;

     ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        font-family: "Source Sans Pro", sans-serif;
        margin-right: 10px;

        &.author-pic a {
          display: block;
          overflow: hidden;
          height: 100px;
          width: 100px;
          border: 1px solid white;
          border-radius: 50%;
        }

        &.post-stats time {
          display: block;
        }
      }
    }
  }
  
  .about.excerpt {
    margin-top: 1em;
  }
  
  .about.post-series {
    font-family: $sans-serif-font-family;
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .about.follow-us {
    font-family: $sans-serif-font-family;
    font-size: 1em;
    font-weight:300;
    padding: 1em;

    p svg {
      padding: 2px 4px;
    }

    .patreon {
      margin-top: 1.5em;
      display: block;
    }
  }

  .about.patreon .patreon-post-link {
    margin-bottom: 1em;
    display: block;
    border: 0;
  }
  
}


.page-image-lightbox {
    font-family: $sans-serif-font-family;
    text-align:center;
    margin-bottom: $site-elements-default-margin-bottom;

    picture {
      border-bottom: 1px solid white;
      border-image-source: linear-gradient(90deg, #252a34 1%, #f7fdff, #252a34 99%);
      border-image-slice: 1;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      display: block;
      cursor: pointer;
    }
}

.with-caption figcaption{
  text-align:center;
  width: 280px;
  margin: 0 auto;
  background-image: linear-gradient(to top, white 10%, rgb(36, 41, 51) 100%);
  border-bottom-right-radius: 8px 8px;
  border-bottom-left-radius: 8px 8px;
  padding: 4px 2px;
  color:#000;
  font-style:italic;
  @include breakpoint($large) {
      width: 400px;
  }
}

.page-image {
  position: relative;
  text-align: center;
  margin-top: (-1 * $site-logo-height) / 2;
  margin-bottom: 2em;
  z-index: 1;

  @include breakpoint($medium) {
    margin-top: (-1.5 * $site-logo-height) / 2;
  }

  @include breakpoint($large) {
    margin-top: (-2 * $site-logo-height) / 2;
  }
  
  img {
    width: 100%;
  }
}

.page-image-caption {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  padding: 0.25em 0.5em;
  color: #fff;
  font-size: 0.75em;
  background: #000;
  text-align: right;
  z-index: 5;
  opacity: 0.5;
  border-radius: 4px 0 0 0;

  > * {
    margin: 0;
    padding: 0;
  }

  a {
    color: #fff;
  }
}

.page-stats-bar {
  font-size : 0.75em;
  list-style: none;
  padding: 0;
  margin: 0;

  @include breakpoint($large) {
    font-size: 1em;
    position: absolute;
    top: -.7em;
    right: .8em;
  }

  li {
    background: #242e39;
    border: 0;
    margin: 0;
    padding: 8px 10px;
    display:inline-block;
    position: relative;

    &.post-share {
      display:none;
    }

    a {
      border: 0;
    }

    @include breakpoint($large) {
      display:block;
      padding: 10px;
      margin: 0 4px;
      box-shadow: 1px 3px 4px 1px black;
      border-left:1px solid #2c3e50;

      &:first-child {
        background:linear-gradient(to bottom, #12171c, #242e39);
      }

      span:before {
        content:'';
        position: absolute;
        border-style: solid;
        transform: rotate(180deg);
        z-index:-1;
        border-width: .8em 0.4em 0em 0em;
        top: 0em;
        left: -0.4em;
        border-color: #000 transparent transparent transparent;        
      }

      &.post-share {
        display: inline-block;

        .social {
          display: inline-block;
          margin-right: 10px;
        }

        .btn {
          padding: 4px 8px;
          margin: 0;
        }
      }
    }
  }

  .page-date,
  .read-time {
    font-family: $base-font-family;
    white-space: nowrap;
    display: block;
  }


  .read-time + .page-date::before {
    content: "\2022";
    padding: 0 0.5em;

    @include breakpoint($large) {


    }
  }
}

.page-author {
  @include clearfix;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1em;
}

.page-taxonomy-container {
  display:inline-block;

  h3 {
    border-right: 2px solid #888;
    border-top-right-radius:10% 50%;
    border-bottom-right-radius:10% 50%;
    padding:4px 8px 4px 0;
    background-color:#00adb5;
    background: linear-gradient(90deg, #252a34 30%, #666 100%);
    font-size:0.5em;
  }

  li.page-taxonomy {
    padding:3px 2px 2px 2px;
    font-size:0.5em;
    display:inline-block;
    text-transform:uppercase;
    font-weight:bold;
    margin:0.75em 0.5em;

    &:not(:last-child)::after{
      content:"//";
      color: #666;
      padding-left:8px;
    }
  }
}

.page-taxonomies-title {
  display: inline;
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;

  @include breakpoint($large) {
    display: inline-block;
    margin: 1.5em 0 0.5em;
    padding: 0;
    font-size: 0.75em;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.page-taxonomies {
  display: inline-block;
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding-right: 6px;
  }
}

.page-taxonomy {
  display: inline;

  &:not(:last-child)::after {
    content: ", ";
  }
}

.page-share {
  margin:0 auto;
  margin-bottom: 1.5rem;
}

.page-comments {
  margin-bottom: 2em;
}

.page-pagination {
  font-family: $base-font-family;
  margin: 2em 0 1em;
  clear: both;

  @include breakpoint($large) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.page-previous,
.page-next {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-decoration: none;
}

.page-next {
  margin-top: 1em;

  @include breakpoint($large) {
    margin-top: 0;
    text-align: right;
  }
}

.page-pagination-label {
  font-family: $base-font-family;
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.page-pagination-title {
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  font-size: 0.9em;
  font-style: italic;
}
.page-pagination-date {
  display:block;
  font-size:0.8em;
}

/*
   Wide Layout Overrides
   ========================================================================== */

.layout--home,
.layout--posts,
.layout--categories,
.layout--tags,
.layout--category,
.layout--tag,
.layout--collection,
.layout--search {
  .page-wrapper {
    max-width: $main-max-width;
  }

  .page-title {
    text-align: center;
  }
}

.layout--post,
.layout--default.featured-post,
.layout--default.latest-post {
  .page-wrapper {
    position: relative;
    background:linear-gradient(to bottom, #2c3e50a1, #252a34 10%);
    padding:0;
    max-width: 100%;
    margin:0;

    @include breakpoint($large){
      margin:0 auto;
      max-width: $main-max-width;
      background:linear-gradient(to bottom, #2c3e50a1, #252a34 30%);
    }

    border-top:0;
    border-left:0;
    border-right:0;    
  }

  .page-title {
    text-align: left;
  }  
}

.layout--categories,
.layout--tags,
.layout--posts {

  .page-wrapper {
    max-width: $xlarge;
    background:linear-gradient(to bottom, #2c3e50a1, #262b34 10%);
    padding:0;
    margin:0;
    padding-top:10px;

    @include breakpoint($large){
      margin:0 auto;
      background:linear-gradient(to bottom, #252a34a1, #262b34 30%);
    }

    border-top:0;
    border-left:0;
    border-right:0;    
  }
}

.layout--home {
  &.quick-reference {
    .page-wrapper {
      @include breakpoint($large) {
        max-width: $xlarge;
      }
    }
  }
  .page-wrapper {
    @include breakpoint($large) {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
  .page-header {
    @include breakpoint($large) {
      grid-column: 1 / -1;
      clear: both; // needed for floated layout
    }
  }

  .page-sidebar {
    margin-bottom: 1em;

    @include breakpoint($large) {
      float: left; // display: grid fallback
      width: 18%; // display: grid fallback
    }

    @supports (display: grid) {
      width: auto;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-content {
    padding:1em;
    font-family: $script-font-family;
    @include breakpoint($large) {
      width: 78%; // display: grid fallback
      min-width: 0;
      font-size:inherit;
    }

    @supports (display: grid) {
      width: auto;
      margin: 0;
    }
  }
}
.layout--post,
.layout--page,
.layout--default.featured-post,
.layout--default.latest-post {

  .page-header {
    border-bottom: 1px solid;
    border-image-slice: 1;
    position: relative;
    font-family: $script-font-family;
    padding: 1em;
    @include breakpoint($large) {
      grid-column: 1 / -1;
      clear: both; // needed for floated layout
    }
  }

  .page-sidebar {
    margin-bottom: 1em;

    @include breakpoint($large) {
      float: right; // display: grid fallback
      width: 18%; // display: grid fallback
    }

    @supports (display: grid) {
      width: auto;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-content {
    font-family: $script-font-family;
    padding:0 1em 1em 1em;
    
    @include breakpoint($large) {
      font-size: 1.1em;
      float: left; // display: grid fallback
      width: 78%; // display: grid fallback
      min-width: 0;

      h1 {
        font-size: 2.5em;
      }
    }

    @supports (display: grid) {
      width: auto;
      margin: 0;
    }

    h1,h2,h3 {
      font-family: $serif-font-family;
    }

    .e-content{
      border-bottom: 1px solid;
      margin-bottom:2em;
      border-image-source: linear-gradient(90deg, #252a34, rgb(0, 173, 181), #252a34);
      border-image-slice: 1;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }

  .page-share {
    // display: grid fallback
    clear: both;
    .btn{
      border: 0;
      text-shadow: none;
      padding: 1em;
    }
  }
}

.page--wide {
  .page-wrapper {
    max-width: $main-max-width;
  }
}
