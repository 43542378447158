/* ==========================================================================
   Author
   ========================================================================== */
.author-avatar-container {
  display:none;

  @include breakpoint($large) {
    display: block;

    .author-avatar {
      margin-bottom: 1em;
      border-radius:100px;
      width: 100px;
      height: 100px;
      display:inline;
    }
  }
}

.author-info {
  margin-left:1em;
  font-size:0.75em;
  .author-name {
    @include breakpoint($large) {
      margin-bottom: 0.5em;
    }
  }

  .author-links {
    display: none;
    margin: 0 0 1.5em;
    padding: 0;
    list-style: none;

    @include breakpoint($large) {
      display: block;
    }
  }
}
.author-link {
  display: inline;
  padding-right: 0.5em;

  @include breakpoint($large) {
    padding: 0.25em;
  }

  a {
    text-decoration: none;
  }
}
