.post-image-gallery {
    background-color:#252a34;
    color:white;
    border-bottom:1px solid white;
    border-top:1px solid white;
    border-image-source: linear-gradient(90deg, #252a34, #007cb2, #252a34);
    border-image-slice: 1;
    margin-bottom: 1em;
    box-shadow:1px 1px 15px #1a1d24;

    .title {
        font-size: 2em;
        font-family: Menoj;
    }

    .description {
        padding: 1em;
        text-align:left;
    }

    .gallery-slider {
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 2px;

        figcaption {
           font-size: 0.5em;
        }
    }
    &.enlarge-lightbox {
        text-align:center;

        .gallery-slider {
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            grid-gap: 1rem;

            figcaption {
              font-size: 0.7em;
            }
        }
    }
    button {        
        &.button-next, 
        &.button-prev {
            padding:0 1em;
            margin:0;
            background:none;
            border:none;
            font-size: 2em;
            color: white;
            text-shadow:1px 1px 2px black;

            &.disabled {
               color: #252a34;
            
                @include breakpoint($large){
                    display:none;
                }
            }
        }

        &.button-next {
            float:right;
        }

        &:focus  {
            outline:none;
        }
    }

    .glider-dots {
        margin-bottom: 1em;
        
        @include breakpoint($large){
        
            button:first-child {
                display:none;
            }
        }
    }

    .glider-dot {
        width:8px;
        height:8px;
        box-shadow:1px 1px 2px black;

        @include breakpoint($large){
            width: 14px;
            height: 14px;
        }
    }

    .glider-dot.active{
        border:1px solid red;
        background: black;
    }

    &.enlarge-lightbox {
        button.button-next {
            float:none;
        }
    }

}
