$midnight: #2c3e50;
$clouds: #ecf0f1;


/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  margin:2px 0 14px 0;
  box-shadow:0 4px 4px -2px rgba(0, 0, 0, 0.5);
  border-radius:8px;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $midnight;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($midnight, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }
  &-content {
    height: 0;
    padding: 0 1em;
    color: $midnight;
    background: white;
    transition: all .35s;

    blockquote {
      color:white;
    }
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;
    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken($midnight, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    height: min-content;
    padding: 1em;
  }
}

.frequently-asked-questions .tab-content {
  a {
    color: #2c3e50;
    border-bottom-color: #2c3e50;
    text-shadow: 1px 1px 2px #666;

    &:hover{
      color: #666;
      text-shadow: 1px 1px 2px #2c3e50;
    }
  }
}